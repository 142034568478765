<template>
  <div>
    <ClFormRow :label="'Entity Type*'" :toolTipText="tooltip" name="entityType" ref="entityType">
      <template #content>
        <multiselect
          v-model="entityType"
          :options="options"
          :hide-selected="true"
          :block-keys="['Tab', 'Enter']"
          required
          :disabled="disabled"
          class="custom-multi-select zq--multiselect"
        />
      </template>
    </ClFormRow>

    <ClFormRow :label="'Entity Id*'" :toolTipText="tooltip" name="entityId" ref="entityId">
      <template #content>
        <multiselect
          v-model="entityId"
          :options="entityIdOptions"
          :hide-selected="true"
          :block-keys="['Tab', 'Enter']"
          :searchable="true"
          required
          :disabled="disabled"
          class="custom-multi-select zq--multiselect"
        />
      </template>
    </ClFormRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { pageConfig } from '@/config';

export default {
  name: "ZqSelectRewardEntityType",
  props: {
    value: Object,
    options: Array,
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    disabled: Boolean
  },
  data() {
    return {
      entityId: null,
      entityType: null,
      entityIdOptions: [],
      sortBy: pageConfig.sortBy,
    }
  },
  created() {
    if (this.value) {
      this.entityId = this.value.entityId;
      this.entityType = this.value.entityType;
    }

    this.$emit('input', { entityId: this.entityId, entityType: this.entityType });
  },
  methods: {
    ...mapActions('competitions', ['handleGetCompetitionsByQuery']),
    ...mapActions('contests', ['handleGetContestsByQuery']),
    ...mapActions('achievements', ['handleGetAchievementsByQuery']),
    async getEntityIds(val) {
      switch(val) {
        case 'Achievement':
          this.entityIdsArray = await this.handleGetAchievementsByQuery({
            queryRequest: {
              sortBy: this.sortBy,
              limit: 999,
              skip: 0
            }
          });
          break;
        case 'Competition':
          this.entityIdsArray = await this.handleGetCompetitionsByQuery({
            queryRequest: {
              must: [
                {
                  queryField: 'status',
                  queryValues: ['Active', 'Ready']
                }
              ],
              limit: 9999,
            }
          });
          break;
        case 'Contest':
          this.entityIdsArray = await this.handleGetContestsByQuery({
            queryRequest: {
              sortBy: this.sortBy,
              limit: 999,
              skip: 0
            }
          });
          break;
      }
      this.entityIdOptions = this.entityIdsArray.map(item => item.id);
    },
  },
  watch: {
    entityType: {
      deep: true,
      handler(val) {
        if (val && this.$refs.entityType) {
          this.$refs.entityType.$el.classList.remove('zq-invalid')

          this.entityId = null

          this.getEntityIds(val)
        }

        this.$emit('input', { entityId: this.entityId, entityType: this.entityType });
      }
    },
    entityId: {
      deep: true,
      handler(val) {
        if (val  && this.$refs.entityId) {
          this.$refs.entityId.$el.classList.remove('zq-invalid')
        }

        this.$emit('input', { entityId: this.entityId, entityType: this.entityType });
      }
    }
  }
}
</script>

<style scoped>

</style>